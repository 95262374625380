.dropzone {
  display: flex;
  margin-top: 32px;
  justify-content: center;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100%;
  min-height: 250px;
  padding: 40px;
  color: $gray-500;
  background-color: $gray-200;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.remove {
  @extend .btn, .btn-outline-dark;

  height: 38px;
  position: absolute;
  right: 0;
  margin-right: 12px;
  margin-top: 10px;
  background-color: rgba(131, 171, 138, 9.08%);
}

.proceed {
  @extend .btn, .btn-outline-dark;
}

.thumb {
  display: flex;
  justify-content: space-between;
  position: relative;
}
