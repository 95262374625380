fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35rem;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
  padding-block-end: 0.625rem;
  min-inline-size: min-content;
  border-width: 3px;
  border-style: groove;
  border-color: $secondary;
  border-image: initial;

  legend {
    text-align: left;
    display: block;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    border-width: initial;
    border-style: none;
    border-color: $secondary;
    border-image: initial;
    float: unset;
    width: auto;
  }

  input[type='password'] {
    & ~ .hide-pass {
      display: none;
    }

    & ~ .show-pass {
      width: 25px;
      position: absolute;
      top: 0.5rem;
      right: 1rem;
    }
  }

  input[type='text'] {
    & ~ .show-pass {
      display: none;
    }

    & ~ .hide-pass {
      width: 25px;
      position: absolute;
      top: 0.5rem;
      right: 1rem;
    }
  }
}
