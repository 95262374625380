.login {
  @extend .dropdown;

  .btn-login {
    @extend .border-0, .mt-2, .bg-light;
  }

  ul {
    position: absolute;
    width: 18rem;
    padding: 0.7rem;
    top: 2.5rem !important;
    left: -15.3rem !important;
    color: rgb(92, 92, 92);

    .please-login {
      @extend .text-start, .ms-1, .mt-2, .mb-3;
    }

    .submit {
      @extend .btn, .btn-secondary, .text-end;
    }
  }
}
