.sidebar {
  @extend .col-2, .h-100, .w-100;

  aside {
    @extend .navbar, .navbar-expand-lg, .h-100;

    .toogle {
      @extend .container-fluid, .h-100;

      button {
        @extend .navbar-toggler;

        span {
          @extend .navbar-toggler-icon;
        }
      }

      #navbar-nav-alt-markup {
        @extend .collapse, .navbar-collapse, .row, .h-100;

        .link {
          @extend .navbar-nav, .flex-column, .border, .border-secondary, .p-3, .mt-4, .h-100;

          a {
            @extend .text-decoration-none, .text-start, .text-dark;

            &:has(span) {
              @extend .text-danger;
            }

            margin-bottom: 1rem;

            &:is(:last-of-type) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
