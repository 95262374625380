.card {
  &:hover {
    cursor: pointer;
  }

  img {
    max-width: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
}
